
import { useNavigate } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import { useContext, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import SessionExpiredModal from '../../../utils/SessionExpiredModal';
import { Button, ButtonBase } from '@mui/material';
import { SnackbarContext } from '../../../App';


export default (props) => {

    const { snackBarShow, setSnackBarShow, snackBarMessage, setSnackBarMessage } = useContext(SnackbarContext);

    const [currentStep, setCurrentStep] = useState('select')
    const [ xeroSelected, setXeroSelected ] = useState(false)
    const [ myobSelected, setMyobSelected ] = useState(false)

    const navigate = useNavigate()

    const [xeroUsername, setXeroUsername] = useState('')
    const [xeroPassword, setXeroPassword] = useState('')
    const [xeroSetupKey, setXeroSetupKey] = useState('')

    const [myobUsername, setMyobUsername] = useState('')
    const [myobPassword, setMyobPassword] = useState('')
    const [myobSetupKey, setMyobSetupKey] = useState('')

    const [sessionExpired, setSessionExpired] = useState(false)

    const [isLoading, setIsLoading] = useState(false)

    const auth = useAuth()

    function selectXeroHandler(){
        setXeroSelected(!xeroSelected)
    }

    function selectMyobHandler(){
        setMyobSelected(!myobSelected)
    }

    function xeroConfirmHandler(){
        if(xeroUsername.length == 0){
            setSnackBarMessage('Username cannot be empty')
            setSnackBarShow(true)
            return
        }

        if(xeroPassword.length == 0){
            setSnackBarMessage('Password cannot be empty')
            setSnackBarShow(true)
            return
        }

        if(xeroSetupKey.length == 6){
            setSnackBarMessage('MFA setup key is not the same as MFA code')
            setSnackBarShow(true)
            return
        }

        if(myobSelected){
            setCurrentStep('myob')
        }else{
            let body = {
                accounts: [
                    {
                        username: xeroUsername,
                        password: xeroPassword,
                        setupKey: xeroSetupKey,
                        type: 'xero'
                    },
                    {
                        username: myobUsername,
                        password: myobPassword,
                        setupKey: myobSetupKey,
                        type: 'myob'
                    }
                ]
            }
            // console.log(body)
            onConfirm(body)
        }
        
    }

    function myobConfirmHandler(){

        if(myobUsername.length == 0){
            setSnackBarMessage('Username cannot be empty')
            setSnackBarShow(true)
            return
        }

        if(myobPassword.length == 0){
            setSnackBarMessage('Password cannot be empty')
            setSnackBarShow(true)
            return
        }

        if(myobSetupKey.length == 6){
            setSnackBarMessage('MFA setup key is not the same as MFA code')
            setSnackBarShow(true)
            return
        }

        let body = {
            accounts: [
                {
                    username: xeroUsername,
                    password: xeroPassword,
                    setupKey: xeroSetupKey,
                    type: 'xero'
                },
                {
                    username: myobUsername,
                    password: myobPassword,
                    setupKey: myobSetupKey,
                    type: 'myob'
                }
            ]
        }
        onConfirm(body)
        // console.log(body)
    }

    function confirmHandler(){
        let body = {
            accounts: [
                {
                    username: xeroUsername,
                    password: xeroPassword,
                    setupKey: xeroSetupKey,
                    type: 'xero'
                },
                {
                    username: myobUsername,
                    password: myobPassword,
                    setupKey: myobSetupKey,
                    type: 'myob'
                }
            ]
        }
        onConfirm(body)

       
    }

    function financialSoftwareConfirmHandler(){

        if(xeroSelected){
            return setCurrentStep('xero')
        }
        if(myobSelected){
            return setCurrentStep('myob')
        }else{
            setSnackBarMessage('Please select at least one option')
            setSnackBarShow(true)
        }
    }

    function onConfirm(body){
        setIsLoading(true)
        fetch(`${window.origin}/api/creator/setup/integration`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${auth.user?.access_token}`
            },
            body: JSON.stringify(body)
        }).then(response => {
            if(response.status === 403){
                setSessionExpired(true)
                throw new Error('Session expired');
            }else{
                setIsLoading(false)
                props.onNext()
            }
        }).catch((error) => {
            console.error(error);
        });

        console.log(body)
    }

    return (
        <>
            <SessionExpiredModal show={sessionExpired}/>
            { currentStep == 'select' && <>
                <header>
                    <p style={{margin: '0px', color: 'white', fontSize: '1vw', fontWeight: 300}}>
                        <span style={{color: 'rgba(255, 255, 255, 0.7)', fontSize: '1vw'}}>Welcome to</span> Gayatri,
                    </p>
                    <h1 style={{fontSize: '1.5vw', marginBottom: '1vw'}}>Financial Software Integration</h1>
                    <p style={{color: 'rgba(255, 255, 255, 0.75)', fontSize: '0.85vw', margin: 0, marginBottom: '1vw'}}>Please select all Accounting Softwares you want to configure (select at least one)</p>
                </header>
                <ButtonBase onClick={selectXeroHandler} style={{width: '100%', cursor: 'pointer', display: 'flex', flexDirection: 'row', alignItems: 'flex-start', padding: '1.2vw', border: 'none', borderRadius: '0.5vw', backgroundColor: xeroSelected? 'rgb(255, 255, 255, 0.9)':'rgb(255, 255, 255, 0.07)', color: xeroSelected? 'rgb(0, 0, 0, 0.9)':'rgb(255, 255, 255, 0.8)', marginBottom: '1.5vw'}}>
                    <div style={{flex: 1, padding: '0.3vw'}}>
                        <p style={{marginBottom: '0.3vw', fontWeight: '600', fontSize: '1.1vw', textAlign: 'left'}}>XERO</p>
                        <p style={{marginBottom: '0', fontSize: '0.8vw', fontWeight: '400', textAlign: 'left', color: xeroSelected? 'rgb(0, 0, 0, 0.9)':'rgb(255, 255, 255, 0.8)'}}>Select this option to set up integration with Xero</p>
                    </div>
                    <div>
                        <Form.Check
                            // style={{height: '1vw', width: '1vw'}}
                            checked={xeroSelected}
                            type='checkbox'
                        />
                    </div>
                </ButtonBase>
                <ButtonBase onClick={selectMyobHandler} style={{width: '100%', cursor: 'pointer', display: 'flex', flexDirection: 'row', alignItems: 'flex-start', padding: '1.2vw', border: 'none', borderRadius: '0.5vw', backgroundColor: myobSelected? 'rgb(255, 255, 255, 0.9)':'rgb(255, 255, 255, 0.07)', marginBottom: '1.5vw'}}>
                    <div style={{flex: 1, padding: '0.3vw'}}>
                        <p style={{marginBottom: '0.3vw', fontWeight: '600', fontSize: '1.1vw', textAlign: 'left', color: myobSelected? 'rgb(0, 0, 0, 0.9)':'rgb(255, 255, 255, 0.8)'}}>MYOB</p>
                        <p style={{marginBottom: '0', fontSize: '0.8vw', fontWeight: '400', color: 'rgb(255, 255, 255, 0.7)', textAlign: 'left', color: myobSelected? 'rgb(0, 0, 0, 0.9)':'rgb(255, 255, 255, 0.8)'}}>Select this option to set up integration with Myob</p>
                    </div>
                    <div>
                        <Form.Check
                            checked={myobSelected}
                            type='checkbox'
                        />
                    </div>
                </ButtonBase>
                
                {/* <button
                    onClick={financialSoftwareConfirmHandler}
                    style={{
                        borderRadius: '0.3vw',
                        background: '#FE475B',
                        border: 'none',
                        color: 'white',
                        padding: '0.7vw 20px',
                        width:'100%',
                        cursor: 'pointer',
                        fontWeight: '600'
                    }}
                    disabled={isLoading}
                >
                    {!isLoading && <><p style={{lineHeight: '1.6vw', margin: 0, fontSize: '1vw'}}>Confirm</p></>}
                    {isLoading && <div class="d-flex justify-content-center" style={{height: '1.6vw'}}>
                        <div class="spinner-border" role="status" style={{width: '1.6vw', height: '1.6vw'}}>
                            <span class="sr-only">Loading...</span>
                        </div>
                        </div>}
                </button> */}
                <Button disabled={isLoading} onClick={financialSoftwareConfirmHandler} variant="outlined" style={{
                        borderColor: 'rgb(255, 255, 255, 1)', color: 'rgb(255, 255, 255, 1)', padding: '0.7vw', marginBottom: '1vw', border: 'none', backgroundImage: 'linear-gradient(rgb(255, 255, 255, 0.05), rgb(10, 10, 10, 0.05)),linear-gradient(#f25252, #f25252)', borderRadius: '0.5vw', textTransform: 'none', fontSize: '1vw', fontWeight: 500, letterSpacing: '0.08vw'
                    , width: '100%', marginTop: '0vw'}}>Confirm</Button>
                
                <Button disabled={isLoading} onClick={confirmHandler} variant="outlined" style={{
                        borderColor: 'rgb(255, 255, 255, 1)', color: 'rgb(255, 255, 255, 0.8)', padding: '0.7vw', marginBottom: '1vw', border: 'none', backgroundColor: 'rgb(255, 255, 255, 0.15)', borderRadius: '0.5vw', textTransform: 'none', fontSize: '1vw', fontWeight: 500, letterSpacing: '0.08vw'
                    , width: '100%', marginTop: '0vw'}}>Skip for now</Button>
            </>}
            { currentStep == 'xero' && <>
                <header>
                    <p style={{margin: '0px', color: 'white', fontSize: '1vw', fontWeight: 300}}>
                        <span style={{color: 'rgba(255, 255, 255, 0.7)', fontSize: '1vw'}}>Welcome to</span> Gayatri,
                    </p>
                    <h1 style={{fontSize: '1.5vw', marginBottom: '1vw'}}>XERO Integration</h1>
                    <p style={{color: 'rgba(255, 255, 255, 0.75)', fontSize: '0.85vw', margin: 0, marginBottom: '1vw'}}>Please provide XERO credential for Gayatri to access your clients</p>
                </header>
                

                <Form.Control
                      type="text"
                      value={xeroUsername}
                      placeholder='Username'
                      onChange={(e) => setXeroUsername(e.target.value)}
                      style={{
                          width: '100%',
                          borderRadius: '0.5vw',
                          backgroundColor: 'rgb(255, 255, 255, 0.05)',
                          fontSize: '0.85vw',
                          color: 'white',
                          border: 'none',
                          padding: '0.25vw 1vw',
                          margin: '1vw auto',
                          height: '3vw'
                      }}
                  />

                <Form.Control
                      type="password"
                      value={xeroPassword}
                      placeholder='Password'
                      onChange={(e) => setXeroPassword(e.target.value)}
                      style={{
                          width: '100%',
                          borderRadius: '0.5vw',
                          backgroundColor: 'rgb(255, 255, 255, 0.05)',
                          fontSize: '0.85vw',
                          color: 'white',
                          border: 'none',
                          padding: '0.25vw 1vw',
                          margin: '1vw auto',
                          height: '3vw'
                      }}
                  />

                <Form.Control
                      type="password"
                      value={xeroSetupKey}
                      placeholder='MFA Setup Key (Optional)'
                      onChange={(e) => setXeroSetupKey(e.target.value)}
                      style={{
                          width: '100%',
                          borderRadius: '0.5vw',
                          backgroundColor: 'rgb(255, 255, 255, 0.05)',
                          fontSize: '0.85vw',
                          color: 'white',
                          border: 'none',
                          padding: '0.25vw 1vw',
                          margin: '1vw auto',
                          height: '3vw'
                      }}
                  />

                <p style={{color: 'rgba(255, 255, 255, 0.75)', fontSize: '0.7vw', margin: 0, marginBottom: '1vw'}}>* Please note that MFA setup key is not the same as MFA code.</p>
                <p style={{color: 'rgba(255, 255, 255, 0.75)', fontSize: '0.7vw', margin: 0, marginBottom: '1vw'}}>* Only provide Setup Key if Multi-factor Authentication is enabled for this account.</p>
                            
                {/* <button
                    onClick={xeroConfirmHandler}
                    style={{
                        borderRadius: '4px',
                        background: '#FE475B',
                        border: 'none',
                        color: 'white',
                        padding: '0.7vw 20px',
                        width:'100%',
                        cursor: 'pointer',
                        fontWeight: '600'
                    }}
                    disabled={isLoading}
                >
                    {!isLoading && <><p style={{lineHeight: '1.6vw', margin: 0, fontSize: '1vw'}}>Confirm</p></>}
                    {isLoading && <div class="d-flex justify-content-center" style={{height: '1.6vw'}}>
                        <div class="spinner-border" role="status" style={{width: '1.6vw', height: '1.6vw'}}>
                            <span class="sr-only">Loading...</span>
                        </div>
                        </div>}
                </button> */}
                <Button onClick={xeroConfirmHandler} variant="outlined" style={{
                        borderColor: 'rgb(255, 255, 255, 1)', color: 'rgb(255, 255, 255, 1)', padding: '0.7vw', marginBottom: '1vw', border: 'none', backgroundImage: 'linear-gradient(rgb(255, 255, 255, 0.05), rgb(10, 10, 10, 0.05)),linear-gradient(#f25252, #f25252)', borderRadius: '0.5vw', textTransform: 'none', fontSize: '1vw', fontWeight: 500, letterSpacing: '0.08vw'
                    , width: '100%', marginTop: '1.2vw'}}>Confirm</Button>
            </>}

            { currentStep == 'myob' && <>
                <header>
                    <p style={{margin: '0px', color: 'white', fontSize: '1vw', fontWeight: 300}}>
                        <span style={{color: 'rgba(255, 255, 255, 0.7)', fontSize: '1vw'}}>Welcome to</span> Gayatri,
                    </p>
                    <h1 style={{fontSize: '1.5vw', marginBottom: '1vw'}}>MYOB Integration</h1>
                    <p style={{color: 'rgba(255, 255, 255, 0.75)', fontSize: '0.85vw', margin: 0, marginBottom: '1vw'}}>Please provide your MYOB credential</p>
                </header>

                <Form.Control
                      type="text"
                      value={myobUsername}
                      placeholder='Username'
                      onChange={(e) => setMyobUsername(e.target.value)}
                      style={{
                          width: '100%',
                          borderRadius: '0.5vw',
                          backgroundColor: 'rgb(255, 255, 255, 0.05)',
                          fontSize: '0.85vw',
                          color: 'white',
                          border: 'none',
                          padding: '0.25vw 1vw',
                          margin: '1vw auto',
                          height: '3vw'
                      }}
                  />

                <Form.Control
                      type="password"
                      value={myobPassword}
                      placeholder='Password'
                      onChange={(e) => setMyobPassword(e.target.value)}
                      style={{
                          width: '100%',
                          borderRadius: '0.5vw',
                          backgroundColor: 'rgb(255, 255, 255, 0.05)',
                          fontSize: '0.85vw',
                          color: 'white',
                          border: 'none',
                          padding: '0.25vw 1vw',
                          margin: '1vw auto',
                          height: '3vw'
                      }}
                  />

                <Form.Control
                      type="password"
                      value={myobSetupKey}
                      placeholder='MFA Setup Key (Optional)'
                      onChange={(e) => setMyobSetupKey(e.target.value)}
                      style={{
                          width: '100%',
                          borderRadius: '0.5vw',
                          backgroundColor: 'rgb(255, 255, 255, 0.05)',
                          fontSize: '0.85vw',
                          color: 'white',
                          border: 'none',
                          padding: '0.25vw 1vw',
                          margin: '1vw auto',
                          height: '3vw'
                      }}
                  />
                
                <p style={{color: 'rgba(255, 255, 255, 0.75)', fontSize: '0.7vw', margin: 0, marginBottom: '1vw'}}>* Please note that MFA Setup Key is not the same as MFA Code.</p>
                <p style={{color: 'rgba(255, 255, 255, 0.75)', fontSize: '0.7vw', margin: 0, marginBottom: '1vw'}}>* Only provide Setup Key if Multi-factor Authentication is enabled for this account.</p>
                             
                {/* <button
                    onClick={myobConfirmHandler}
                    style={{
                        borderRadius: '4px',
                        background: '#FE475B',
                        border: 'none',
                        color: 'white',
                        padding: '0.7vw 20px',
                        width:'100%',
                        cursor: 'pointer',
                        fontWeight: '600'
                    }}
                    disabled={isLoading}
                >
                    {!isLoading && <><p style={{lineHeight: '1.6rem', margin: 0}}>Confirm</p></>}
                    {isLoading && <div class="d-flex justify-content-center" style={{height: '1.6rem'}}>
                        <div class="spinner-border" role="status" style={{width: '1.6rem', height: '1.6rem'}}>
                            <span class="sr-only">Loading...</span>
                        </div>
                        </div>}
                </button> */}
                <Button onClick={myobConfirmHandler} variant="outlined" style={{
                        borderColor: 'rgb(255, 255, 255, 1)', color: 'rgb(255, 255, 255, 1)', padding: '0.7vw', marginBottom: '1vw', border: 'none', backgroundImage: 'linear-gradient(rgb(255, 255, 255, 0.05), rgb(10, 10, 10, 0.05)),linear-gradient(#f25252, #f25252)', borderRadius: '0.5vw', textTransform: 'none', fontSize: '1vw', fontWeight: 500, letterSpacing: '0.08vw'
                    , width: '100%', marginTop: '1.2vw'}}>Confirm</Button>
            </>}
            

        </>
    )
}

const styles = {
    input: {
        width: '100%',
        borderRadius: '0.5vw',
        background: 'rgb(255, 255, 255, 0.08)',
        color: 'white',
        border: 'none',
        padding: '0.25vw 1vw',
        margin: '0 auto',
        marginBottom: '1.3vw',
        height: '3vw',
        fontSize: '0.85vw'
    }
}
