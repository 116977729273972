import Modal from 'react-bootstrap/Modal';
import React, { useState, useRef, useEffect, useContext } from 'react';
import { Typeahead, TypeaheadMenu } from 'react-bootstrap-typeahead';
import { Accordion, ButtonGroup, Offcanvas, OverlayTrigger, Spinner, Tab, Tabs, ToggleButton, Tooltip } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { useAuth } from 'react-oidc-context';

import { styled } from '@mui/material/styles';

import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';

import TransparentButton from "../../../utils/TransparentButton";
import TransparentButtonDark from "../../../utils/TransparentButtonDark";

import SyncIcon from '@mui/icons-material/Sync';

import SearchIcon from '@mui/icons-material/Search';



import Papa from "papaparse";

import validator from 'validator';

import VerifiedIcon from '@mui/icons-material/Verified';

import NewReleasesIcon from '@mui/icons-material/NewReleases';


import { 
    useNavigate
} from "react-router-dom";
import { Autocomplete, Backdrop, Badge, Box, ButtonBase, CircularProgress, Dialog, DialogContent, DialogContentText, DialogTitle, FormControl, IconButton, InputLabel, List, ListItemButton, ListSubheader, MenuItem, Select, TextField, ThemeProvider, ToggleButtonGroup, createTheme } from '@mui/material';
import SessionExpiredModal from '../../../utils/SessionExpiredModal';
import MessageModalModified from '../../../utils/MessageModalModified';
import { SnackbarContext } from '../../../App';
import SyncClientsModal from './SyncClientsModal';



const TextButton = styled(Button)(({ theme }) => ({
    color: 'white',
    textTransform: 'none',
    padding: '0.5vw 0.75vw'
  }));


const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });

function isValidABN (ABN) {
    if(!ABN) {
        return false
    }

    if (ABN == ''){
        return false
    }

    console.log(ABN.length === 11)

    const result = validator.isNumeric(ABN) && ABN.length === 11;
    if(result) {
        return ABN
    } else {
        return false
    }
}

function formatABN(abn) {

    if(abn == 'NOT PROVIDED'){
        return abn
    }

    // Remove any non-digit characters in case the input is not clean
    let str = abn.replace(/\D/g, '');

    // Check if the input is exactly 11 digits, if not, handle as needed
    if (str.length !== 11) {
        console.error("Invalid ABN: ABN must be exactly 11 digits");
        return null;
    }

    // Insert spaces to format as ABN
    return str.replace(/(\d{2})(\d{3})(\d{3})(\d{3})/, '$1 $2 $3 $4');
}


export default (props) => {

    const auth = useAuth()

    const [rawData, setRawData] = useState([])

    const [backdropOpen, setBackdropOpen] = React.useState(false);
    const handleBackdropClose = () => {
        setBackdropOpen(false);
    };
    const handleBackdropOpen = () => {
        setBackdropOpen(true);
    };

    const navigate = useNavigate()

    // popup
    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false)
    };
    const handleShow = () => {
        if(data.length < maxClients){
            setCurrentABN('')
            setCurrentASClientName('')
            setClientAdding('init')
            setCurrentClientName('')
            if(asAccounts.length === 0){
                setCurrentAccountingSoftware('none')
            }else{
                setCurrentAccountingSoftware(asAccounts[0])
            }
            setcurrentAccountingBasis('Cash')
            setcurrentBasLodgement('Quarterly')
            setShow(true)
        }else{
            setUpgradeRequireShow(true)
        }
    };

    const [ uploadAllowed, setUploadAllowed ] = useState(false)

    const [ maxClients, setMaxClients ] = useState(0)

    const [filteredData, setFilteredData] = useState([])

    const [statusFilter, setStatusFilter] = useState('all')

    const [userData, setUserData] = useState([])

    const [assignedTo, setAssignedTo] = useState([])


    const [ asAccounts, setAsAccounts ] = useState([])

    const { snackBarShow, setSnackBarShow, snackBarMessage, setSnackBarMessage } = useContext(SnackbarContext);


    // State to manage popup visibility
    const [uploadClientShow, setUploadClientShow] = useState(false);

    function agentEmailChangedHandler(agents) {
        const emailsList = agents.map(email => (typeof email === 'object' ) ? email.label : email )
        setOwnerEmails([...new Set(emailsList)])
    }

    // Handler function to show the popup
    const handleUploadClientShow = () => {
        setUploadClientShow(true);
    };

    // Handler function to hide the popup
    const handleUploadClientClose = () => {
        setUploadClientShow(false);
    };

    // Handler function to handle the template file download
    const handleDownloadTemplate = () => {
        // Add logic to download the template file
        console.log('Download template initiated');
    };


    const [ uploadedClients, setUploadedClients ] = useState([])

    const [currentAccountingSoftware, setCurrentAccountingSoftware] = useState('xero');

    const [ message, setMessage ] = useState('')

    const [ clientAdding, setClientAdding ] = useState('added')

    const [sessionExpired, setSessionExpired] = useState(false)

    const [ upgradeRequiredShow, setUpgradeRequireShow ] = useState(false)

    const [syncModalShow, setSyncModalShow] = useState(false)


    function fetchAvailableAS(){
        fetch(`${window.origin}/api/creator/as-accounts`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${auth.user?.access_token}`
            }
        }).then(response => {
            return response.json();
        }).then(data => {
            const availableAccounts = data.filter(item => item.username != '').map(item => item.acc_type)
            setAsAccounts(availableAccounts)
            if(availableAccounts.length === 0){
                setCurrentAccountingSoftware('none')
            }else{
                setCurrentAccountingSoftware(availableAccounts[0])
            }
        }).catch((error) => {
            console.error(error);
        });
    }


    function uploadHandler(event){
        setUploadAllowed(false)
        let count = 2
        // try{
        Papa.parse(event.target.files[0], {
            header: true,
            skipEmptyLines: true,
            complete: function (results) {
                try{
                    let clients = results.data.map(client => {
                        let adminAccountantEmails = client["Owners"] == ""? [] : client["Owners"].replace(" ", "").split(",")
                        if(!isValidABN(client["ABN"])){
                            throw 'Invalid ABN'
                        }
                        if(!adminAccountantEmails.includes(auth.user?.profile.preferred_username)){
                            adminAccountantEmails.unshift(auth.user?.profile.preferred_username)
                        }
                        count ++
                        return {
                            ABN: client["ABN"],
                            clientName: client["Name"],
                            financialSWAccount: "",
                            financialSWType: "xero",
                            adminAccountantEmails: adminAccountantEmails,
                            taxAgentEmail: client["Users"] == ""? [] : client["Users"].replace(" ", "").split(",")
                        }
                    })
                    setUploadedClients(clients)
                    setUploadAllowed(true)
                    setMessage(`CSV file validated!`)
                }catch(e){
                    setMessage(`Error at line ${count}: ${e}`)
                }
            }
        });
        // }catch(e){
        //     console.log(`Error at row ${count}`)
        //     console.log(e)
        // }
      
    }

    // Handler function to handle the file upload
    const handleUploadFile = () => {
        // Add logic to upload the selected file
        console.log('File upload initiated');
    };

    const handleRefresh = () => {
        if (!isLoading) {
            setFilteredData([])
            setRawData([])
            setData([])
            fetchData();
          }
      };

    const [data, setData] = useState([]);
    const [showPopup, setShowPopup] = useState(false);
    const [currentRowId, setCurrentRowId] = useState(null);

    // add new row when create data
    const idRef = useRef();
    const abnRef = useRef();
    const clientNameRef = useRef();
    const alternativeNameRef = useRef();
    const asClientNameRef = useRef();
    const passwordOptionalRef = useRef();
    const accountingSoftwareRef = useRef();
    const AccountingBasis =  useRef();
    const basLodgement =  useRef();

    const [errorMessage, setErrorMessage] = useState('An unexpected error has occured. Please try again later.')

    const [errorTitle, setErrorTitle] = useState('Unexpected Error')

    // search textbox
    const [searchQuery, setSearchQuery] = useState("");
    
    useEffect(() => {
        setFilteredData(data.filter(item =>
            item.client_abn.includes(searchQuery) ||
            item.client_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            item.as_client_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            item.financial_sw_type.toLowerCase().includes(searchQuery.toLowerCase())
        ))
    }, [searchQuery, data])

    // edit
    const [currentABN, setCurrentABN] = useState('');
    const [currentClientName, setCurrentClientName] = useState('');
    const [currentAlternativeName, setCurrentAlternativeName] = useState('');
    const [currentASClientName, setCurrentASClientName] = useState('');
    

    const [currentATORetrieveal, setCurrentATORetrieveal] = useState(true);
    const [currentAccountingBasis, setcurrentAccountingBasis] = useState('Accrual');
    const [currentBasLodgement, setcurrentBasLodgement] = useState('Quarterly');
    const [currentStatus, setCurrentStatus] = useState('');
    const [currentOwnerEmail, setCurrentOwnerEmail] = useState('');
    const [currentUserEmail, setCurrentUserEmail] = useState('');

    const [displayMode, setDisplayMode] = useState('active')

    const handleAbnChange = (e) => {
        setAbnValue(e.target.value);
    };

    const handleClientNameChange = (e) => {
        setClientNameValue(e.target.value);
    };

    const handleAsClientNameChange = (e) => {
        setAsClientNameValue(e.target.value);
    };

    const handleAccountingBasisChange = (e) => {
        setAccountingBasisValue(e.target.value);
    };

    const handleBasLodgementChange = (e) => {
        setBasLodgementValue(e.target.value);
    };

    const [abnValue, setAbnValue] = useState('');
    const [clientNameValue, setClientNameValue] = useState('');
    const [asClientNameValue, setAsClientNameValue] = useState('');
    const [accountingBasisValue, setAccountingBasisValue] = useState('');
    const [basLodgementValue, setBasLodgementValue] = useState('');

    const [isLoading, setIsLoading] = useState(false)

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 8;

    let indexOfLastItem = currentPage * itemsPerPage;
    let indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const [currentItems, setCurrentitems] = useState(data.slice(indexOfFirstItem, indexOfLastItem))

    const displayedFirstItem = indexOfFirstItem + 1; // +1 to convert 0-indexed to 1-indexed
    const displayedLastItem = Math.min(indexOfLastItem, filteredData.length); // Use filteredData length here

    const [abnValidity, setAbnValidity] = useState(true);

    const [reloadButtonDisabled, setReloadButtonDisabled] = useState(false);

    // Page change handlers
    const nextPage = () => {
        if (currentPage < Math.ceil(data.length / itemsPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };

    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    useEffect(() => {
        // Calculate the items for the current page from filtered data
        let indexOfLastItem = currentPage * itemsPerPage;
        let indexOfFirstItem = indexOfLastItem - itemsPerPage;
        setCurrentitems(filteredData.slice(indexOfFirstItem, indexOfLastItem));
    }, [filteredData, currentPage]);    

    useEffect(() => {
        if (currentRowId !== null) {
            const currentItem = data.find(item => item.id === currentRowId);
            if (currentItem) {
                setCurrentABN(currentItem.client_abn);
                setCurrentClientName(currentItem.client_name);
                setCurrentAlternativeName(currentItem.alt_client_name);
                setCurrentASClientName(currentItem.as_client_name);
                setCurrentAccountingSoftware(currentItem.financial_sw_type);
                setcurrentAccountingBasis(currentItem.accounting_basis);
                setcurrentBasLodgement(currentItem.bas_lodgement);
                setCurrentStatus(currentItem.status);
                setCurrentOwnerEmail(currentItem.owners);
                setCurrentUserEmail(currentItem.users);
            }
        }
    }, [currentRowId, data]);

    // new
    const [ownerEmails, setOwnerEmails] = useState([]);
    const [userEmails, setUserEmails] = useState([]);

    // useEffect(() => {
    //   if (data.length > 0) {
    //     setOwnerEmails(data[0].owners);
    //     setUserEmails(data[0].users);
    //   }
    // }, [data]);

    function transformData(prevState, users){
        const newState = [...prevState]

        newState.forEach(client => {


            let owners = client.owners
            console.log(users.filter(item => owners.includes(item.email)))
            client.owners = users.filter(item => owners.includes(item.email))
        })
        return newState
    }

    function  retrieveUserData(){
        fetch(`${window.origin}/api/users`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${auth.user?.access_token}`
            }
        }).then(response => {
            if(response.status === 403){
                setSessionExpired(true)
                throw new Error('Session expired');
            }else{
                return response.json()
            }
        }).then(users => {
            setUserData(users.filter(item => item.email !== auth.user?.profile.email))

        }).catch((error) => {
            console.error(error);
        });
    }

    // useEffect(()=> {
    //     if (auth.user?.profile.accountant.ssid){
    //         console.log('execute')
    //         fetch(`${window.origin}/api/users`, {
    //             method: "GET",
    //             headers: {
    //                 "Content-Type": "application/json",
    //                 "Authorization": `Bearer ${auth.user?.access_token}`
    //             }
    //         }).then(response => {
    //             if(response.status === 403){
    //                 setSessionExpired(true)
    //                 throw new Error('Session expired');
    //             }else{
    //                 return response.json()
    //             }
    //         }).then(users => {
    //             setUserData(users.filter(item => item.email !== auth.user?.profile.email))

    //         }).catch((error) => {
    //             console.error(error);
    //         });
    //     }
    // }, [auth.user?.profile.accountant.ssid])

    useEffect(() => {
        fetchAvailableAS()
        setIsLoading(true)
        handleRefresh()

        fetch(`${window.origin}/api/subscription/maxclient`, {
            method: 'GET',
            headers: {
            Authorization: `Bearer ${auth.user?.access_token}`
            },
        })
        .then((response) => {
            if(response.status === 403){
                setSessionExpired(true);
                throw new Error('Session expired');
            }else{
                return response.json();
            }
        })
        .then((data) => {
            setMaxClients(data.limit)
        })
        .catch((error) => {
          console.error('Error fetching clients:', error);
        });

    }, []);

    useEffect( () => {
        // console.log(rawData)
        // console.log(statusFilter)
        const successData = rawData.filter(item =>
            item.status.includes('done')
        )

        const failData = rawData.filter(item =>
            item.status.includes('fail')
        )

        if(displayMode == 'active'){
            setData(rawData.filter(item =>
                item.status.includes('done')
            ))
        }else{
            setData(rawData.filter(item =>
                item.status.includes('fail')
            ))
        }
    }, [displayMode, rawData])

    // const axios = require('axios');

    
    // const isValidABN = async (abn) => {
    //     const abnPattern = /^(\d{11})$/;
    //     return abnPattern.test(abn);

        // const name = 'coles';
        // const postcode = '2250';
        // const legalName = '';		
        // const tradingName = '';	
        // const NSW = '';			
        // const SA = '';				
        // const ACT = '';			
        // const VIC = '';			
        // const WA = '';				
        // const NT = '';				
        // const QLD = '';			
        // const TAS = '';			
        // const authenticationGuid = '';		
    
        // try {
        //     const response = await axios.get(`https://abr.business.gov.au/abrxmlsearchRPC/AbrXmlSearch.asmx/ABRSearchByNameSimpleProtocol?name=${name}&postcode=${postcode}&legalName=${legalName}&tradingName=${tradingName}&NSW=${NSW}&SA=${SA}&ACT=${ACT}&VIC=${VIC}&WA=${WA}&NT=${NT}&QLD=${QLD}&TAS=${TAS}&authenticationGuid=${authenticationGuid}`, { responseType: 'arraybuffer' });

        //     // Process response data as needed
        //     console.log(response.data.toString('utf-8'));
        //     // Return true or false based on validation result
        //     return true;
        // } catch (error) {
        //     console.error('Error occurred:', error);
        //     return false;
        // }
    // };

    const handleABNChange = async () => {
        const abn = abnRef.current.value;
        if (abn) {
            const isValid = await isValidABN(abn);
            setAbnValidity(isValid);
        } else {
            setAbnValidity(null);
        }
    };
    
    const fetchData = () => {

        fetch(`${window.origin}/api/users`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${auth.user?.access_token}`
            }
        }).then(response => {
            if(response.status === 403){
                setSessionExpired(true)
                throw new Error('Session expired');
            }else{
                return response.json()
            }
        }).then(users => {
            let tempUserData = users
            setUserData(tempUserData)
            setReloadButtonDisabled(true);
            setIsLoading(true);
            fetch(`${window.origin}/api/creator/clients`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${auth.user?.access_token}`
                },
            })
            .then((response) => {
                if (response.status === 403) {
                    setSessionExpired(true);
                    throw new Error('Session expired');
                } else {
                    return response.json();
                }
            })
            .then((clients) => {
                let transformedClients = transformData(clients, tempUserData)
                setRawData(transformedClients);
                setIsLoading(false);
                setReloadButtonDisabled(false);
            })
            .catch((error) => {
            console.error('Error fetching clients:', error);
            setReloadButtonDisabled(false);
            });

        }).catch((error) => {
            console.error(error);
        });
        
    };
      

      async function addUser() {
        console.log('clicked');
        handleBackdropOpen()
        console.log(currentAccountingSoftware)
        // setCurrentABN(isValidABN(abnRef?.current?.value) ? abnRef?.current?.value : 'NOT PROVIDED')
        // setCurrentASClientName(asClientNameRef?.current?.value !== '' ? asClientNameRef?.current?.value : 'NOT PROVIDED')
        console.log(isValidABN(currentABN) || 'NOT PROVIDED')
        try{
            let response = await fetch(`${window.origin}/api/creator/clients`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${auth.user?.access_token}`,
                },
                body: JSON.stringify({
                    clients: [{
                        client_abn: isValidABN(currentABN) || 'NOT PROVIDED',
                        client_name: currentClientName || '',
                        alt_client_name: '',
                        as_client_name: currentASClientName !== '' ? currentASClientName : 'NOT PROVIDED',
                        financial_sw_type: currentAccountingSoftware || '',
                        ato_data: currentATORetrieveal || false,
                        accounting_basis: currentAccountingBasis || '',
                        bas_lodgement: currentBasLodgement || '',
                        owners: [auth.user?.profile.preferred_username],
                        password: passwordOptionalRef?.current?.value || '',
                        users: userEmails,
                    }]
                }),
            })
            console.log(response.status)
            if(response.status != 200){
                setClientAdding('error')
                let json_resp = await response.json()
                if (json_resp.error == 'Client already exists.'){ 
                    setSnackBarMessage('Client already exist')
                    setSnackBarShow(true)
                    handleClose()
                }else{
                    setSnackBarMessage('Unknown error occurred. Please try again later')
                    setSnackBarShow(true)
                    handleClose()
                }
                    
            }else{
                setSnackBarMessage('Client successfully added')
                setSnackBarShow(true)
                handleClose()
                handleRefresh()
            }

            if(response.status === 403){
                setSessionExpired(true);
                throw new Error('Session expired');
            }
        }catch(e){
            setSnackBarMessage('Unknown error occurred. Please try again later')
            setSnackBarShow(true)
            handleClose()
        }
        handleBackdropClose()
    }

    const [deleteID, setDeleteID] = useState(null);

    const [deleteDialogShow, setDeleteDialogShow] = useState(false)

    const handleDeleteDialogClickOpen = () => {
        setDeleteDialogShow(true);
    };

    function deleteClient(idToDelete) {
        console.log("Attempting to delete client with ID:", idToDelete);

        setShowPopup(false)


        fetch(`${window.origin}/api/creator/clients?id=${idToDelete}`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${auth.user?.access_token}`,
            },
        })
        .then(response => {

            if (response.status === 403) {
                auth.signoutSilent()
                setSessionExpired(true)
            } else if (response.ok) {
                // Update your client data state to remove the deleted client
                setData(prevData => prevData.filter(item => item.id !== idToDelete));
                handleClose(); // Close the modal after successful deletion
                setDeleteDialogShow(false)
                handleRefresh()
            } else {
                // Handle other response statuses or errors
                console.error("Error deleting client. Status:", response.status);
            }
        })
        .catch(error => {
            console.error("Error deleting client:", error)
            handleRefresh()
      });
    }

    function editClientHandler(client) {
      // Check if client object has an id
      if(!client.id) {
          console.error('Client ID is missing');
          return;
      }

      setIsLoading(true)

      fetch(`${window.origin}/api/creator/clients`, {
          method: "PUT",
          headers: {
              "Authorization": `Bearer ${auth.user?.access_token}`,
              "Content-Type": "application/json"
          },
          body: JSON.stringify(client),
      })
      .then(response => {
        if(response.status === 403){
            setSessionExpired(true)
            throw new Error('Session expired');
        }
        return response.json();
      })
      .then(updatedClient => {
        //   setData(prevData => {
        //       return prevData.map(item => item.id === updatedClient.id ? updatedClient : item);
        //   });

        // setShowPopup(false)
        setSnackBarMessage('Client details updated')
        setSnackBarShow(true)

        fetchData()

        // handleRefresh()

        

        console.log(updatedClient)
      })
      .catch(error => {
          console.error('Error editing client:', error);
      });
    }

    useEffect(() => {
        let interval = setInterval(() => {
            fetchData()
        }, 5000)
        return () => {
            clearInterval(interval);
            console.log("Interval cleared.");
          };
    }, [])

    return (
        <div className={props.imgClass} style={{ textAlign: 'center', position: 'relative', height: '100%', display: 'flex', flexDirection: 'row' }}>
            <SessionExpiredModal show={sessionExpired}/>
            <Backdrop
                sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
                open={backdropOpen}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <MessageModalModified show={upgradeRequiredShow} onClose={() => {setUpgradeRequireShow(false)}} onClick={() => {navigate('/mydigitalworkers/gayatri/settings', {state: {tab: "SubscriptionSettings"}})}} message={'Upgrade Required'} description={'You have reached the maximum number of clients for the current plan. Please upgrade to add more clients.'} buttonText={'Upgrade'}/>
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '81vh' }}>

                <div style={{}}>
                    {/* top button */}
                    <div style={{margin: '1.3vw 1vw', display: 'flex', flexDirection: 'row', alignItems: 'center', lineHeight:'0'}}>
                        <div style={{ flex: 1, paddingLeft: '1vw' }}>
                            {/* <Form.Group style={{ textAlign:'left', marginLeft: '0.5vw'}}>
                                <i style={{fontSize:'0.9vw'}} className="fa-solid fa-magnifying-glass"></i>
                                <Form.Control 
                                    style={{
                                        display: 'inline', height: '3vw', backgroundColor: 'rgba(255,255,255,0)', 
                                        border: '1px solid rgb(255, 255, 255, 0)', borderRadius: '6px', fontSize: '0.9vw', maxWidth: '40%', marginLeft:'0' , fontWeight: '200'
                                    }} 
                                    type="text" 
                                    placeholder="Search" 
                                    value={searchQuery}
                                    onChange={e => setSearchQuery(e.target.value)}
                                />

                            </Form.Group> */}
                            <div style={{textAlign: 'left', marginBottom: '0.5vw', width: '15vw'}}>
                                    <div style={{backgroundColor: 'rgb(255, 255, 255, 0.08)', borderRadius: '50px', padding: '0.2vw'}}>
                                        <ThemeProvider theme={darkTheme}>

                                            <IconButton aria-label="delete" style={{backgroundColor: 'rgb(255, 255, 255, 0.15)', padding: '0.5vw', fontSize: '0.5vw', color: 'rgb(255, 255, 255, 0.7)', cursor: 'none'}}>
                                                <SearchIcon id="client-search-bar" style={{fontSize: '1vw'}}/>
                                            </IconButton>
                                        </ThemeProvider>
                                        <Form.Control 
                                            style={{
                                                display: 'inline',  backgroundColor: 'rgba(255,255,255,0)', padding: 0, paddingLeft: '0.8vw', paddingRight: '0.8vw',
                                                border: '1px solid rgb(255, 255, 255, 0)', borderRadius: '6px', fontSize: '0.9vw', maxWidth: '80%', marginLeft:'0' , fontWeight: '200'
                                            }} 
                                            className='shadow-none'
                                            type="text" 
                                            placeholder="Search" 
                                            value={searchQuery}
                                            onChange={e => setSearchQuery(e.target.value)}
                                        />
                                    </div>
                            </div>
                        </div>
                        <div style={{flex: 1, textAlign: 'right', paddingLeft: '1vw', paddingRight: '1vw'}}>
                            <span style={{flex: 1, fontSize: '1.8vh', textAlign: 'center', flexDirection: 'row'}}>
                                <div style={{display: 'inline-block', backgroundColor: 'rgb(255, 255, 255, 0.1)', borderRadius: '2vw', padding: '0.2vw'}}>
                                    <OverlayTrigger
                                        key={'top-submit'}
                                        placement={'top'}
                                        overlay={
                                            <Tooltip id={`tooltip-submit`}>
                                                Active Clients
                                            </Tooltip>
                                        }
                                        >
                                    <span style={{}}>
                                        
                                            <TransparentButton style={{padding: '0.3vw 0.75vw'}} onClick={() => {
                                                setDisplayMode('active')
                                            }} selected={displayMode == 'active'}>
                                                {/* <Badge badgeContent={4} color="success"> */}
                                                    <VerifiedIcon style={{fontSize: '1vw'}}/>
                                                {/* </Badge> */}
                                            </TransparentButton>
                                    

                                        
                                    </span>
                                    </OverlayTrigger>
                                    <OverlayTrigger
                                        key={'top-submit'}
                                        placement={'top'}
                                        overlay={
                                            <Tooltip id={`tooltip-submit`}>
                                                Fail Clients
                                            </Tooltip>
                                        }
                                        >
                                        <span style={{}}><TransparentButton style={{padding: '0.3vw 0.75vw'}} onClick={() => {
                                                setDisplayMode('fail')
                                            }} selected={displayMode == 'fail'}><NewReleasesIcon  style={{fontSize: '1vw'}}/></TransparentButton></span>
                                    </OverlayTrigger>
                                </div>
                            </span>
                            { auth.user?.profile.groups.includes("gayatri_admin") && 
                            <span style={{borderLeft: 'solid 1px rgb(255, 255, 255, 0.3)', marginLeft: '1vw'}}>
                            {/* </Box> */}
                           

                                <OverlayTrigger
                                key={'top-submit'}
                                placement={'top'}
                                overlay={
                                    <Tooltip id={`tooltip-submit`}>
                                        Add New Client
                                    </Tooltip>
                                }
                                >
                                    <TextButton id="add-new-client" variant='text' style={{ fontSize: '0.9vw',cursor: 'pointer', marginLeft: '0.5vw', padding: '0.75vw', minWidth: 0, borderRadius: '2vw', backgroundColor: 'rgb(255, 255, 255, 0.1)', marginRight: '0.5vw', marginLeft: '1vw'}} onClick={handleShow}>
                                        <i className="fa-solid fa-plus" style={{fontSize: '0.9vw', width: '0.95vw', height: '0.95vw'}}></i>
                                    </TextButton>
                                </OverlayTrigger>
                            
                            
                            {/* <span style={{fontSize: '0.9vw', paddingLeft: '1.8vw', cursor: 'pointer', cursor: 'pointer'}} onClick={handleUploadClientShow}><i class="fa-solid fa-arrow-up" style={{paddingRight: '8px', fontSize: '0.9vw'}}></i>Upload</span> */}
                            {/* <TextButton style={{fontSize: '0.9vw', marginLeft: '1.25vw', padding: '0.4vw 0.5vw', marginRight:'0.5vw'}} onClick={handleRefresh}><i class="fa-solid fa-sync" style={{paddingRight: '8px'}}></i>Refresh</TextButton> */}
                            <OverlayTrigger
                                    key={'top-submit'}
                                    placement={'top'}
                                    overlay={
                                        <Tooltip id={`tooltip-submit`}>
                                            Sync Clients
                                        </Tooltip>
                                    }
                                >
                                     <TextButton id="refresh-client-button" variant='text' style={{fontSize: '0.9vw', cursor: 'pointer', padding: '0.75vw', minWidth: 0, borderRadius: '2vw', backgroundColor: 'rgb(255, 255, 255, 0.1)', marginLeft: '0.4vw' , marginRight: '0.4vw'}} onClick={() => {setSyncModalShow(true)}}>
                                        <i class="fa-solid fa-rotate" style={{fontSize: '0.9vw', width: '0.95vw', height: '0.95vw'}}></i>
                                    </TextButton>

                                </OverlayTrigger>

                            </span>}

                            {/* <OverlayTrigger
                                key={'top-submit'}
                                placement={'top'}
                                overlay={
                                    <Tooltip id={`tooltip-submit`}>
                                        Refresh
                                    </Tooltip>
                                }
                            >
                                    <TextButton id="refresh-client-button" variant='text' style={{fontSize: '0.9vw', cursor: 'pointer', padding: '0.75vw', minWidth: 0, borderRadius: '2vw', backgroundColor: 'rgb(255, 255, 255, 0.1)', marginLeft: '0.4vw'}} onClick={handleRefresh}>
                                    <i class="fa-solid fa-rotate-right" style={{fontSize: '0.9vw', width: '0.95vw', height: '0.95vw'}}></i>
                                </TextButton>

                            </OverlayTrigger> */}
                           
                        </div>
                    </div>

                    <SyncClientsModal show={syncModalShow} asAccounts={asAccounts} onHide={() => {
                        setSyncModalShow(false)
                    }}/>


                    {/* popup for add client*/}
                    <Modal
                        show={show}
                        onHide={handleClose}
                        dialogClassName="modal-50w"
                        aria-labelledby="example-custom-modal-styling-title"
                    >     
                        <Modal.Body style={{padding: '2vw 3vw', paddingBottom: '0vw', textAlign: 'center'}}>

                            <h4 style={{textAlign: 'center', fontSize: '1.3vw', marginBottom: '3vw', fontWeight: '500'}}>{ clientAdding == 'init' && <>New Client</>}
                                { clientAdding == 'details' && <>{currentClientName}</>}
                                { clientAdding == 'added' && <>Client Successfully Added</>}
                                { clientAdding == 'error' && <>{errorTitle}</>}</h4>

                            { clientAdding == 'init' && <>
                                <Form>
                                    {/* Client Name */}
                                    <Form.Group style={{ marginBottom: '2vw', textAlign: 'left' }}>
                                        <p style={{ fontSize: '0.9vw', fontWeight: '500', marginLeft: '0.2vw' }}>Client Name</p>
                                        <p style={{fontSize: '0.75vw', color: 'rgb(255, 255, 255, 0.6)', marginLeft: '0.2vw'}}>Client name must be unique across your organization.</p>
                                        <Form.Control style={{ height: '3vw', backgroundColor: 'rgba(0,0,0,.25)', border: 'none', borderRadius: '0.5vw', fontSize: '0.8vw', paddingLeft: '1vw' }} type="text" required placeholder="Client Name" value={currentClientName} ref={clientNameRef} onChange={(e) => setCurrentClientName(e.target.value)} />
                                    </Form.Group>

                                    {/* Accounting Software */}
                                    <Form.Group style={{ marginBottom: '2vw', textAlign: 'left' }}>
                                        <p style={{ fontSize: '0.9vw', fontWeight: '500', marginLeft: '0.2vw' }}>ATO Data Retrieval</p>

                                        <p style={{fontSize: '0.75vw', color: 'rgb(255, 255, 255, 0.6)', marginLeft: '0.2vw'}}>This option allows Gayatri to download data from Australian Tax Office and populate corresponding tabs in workpaper.</p>

                                        {/* <div style={{display: 'inline-block', backgroundColor: 'rgb(255, 255, 255, 0.05)', borderRadius: '2vw', padding: '0.15vw'}}>
                    
                                            <span style={{}}>
                                                
                                                    <TransparentButton style={{padding: '0.3vw 1vw'}} onClick={() => {
                                                        setCurrentATORetrieveal(true)
                                                    }} selected={currentATORetrieveal}>Yes</TransparentButton>
                                            

                                                
                                            </span>

                                
                                            <span style={{}}><TransparentButton style={{padding: '0.3vw 1vw'}} onClick={() => {
                                                    setCurrentATORetrieveal(false)
                                                }} selected={!currentATORetrieveal}>No</TransparentButton></span>
                    
                                        </div> */}

                                        <div style={{display: 'inline-block', backgroundColor: 'rgb(0, 0, 0, 0.2)', borderRadius: '0.6vw', padding: '0.3vw'}}>
                      


                                            {[true, false].map( (option, index) => <span style={{}}>
                                                <TransparentButton style={{padding: '0.3vw 0.75vw', borderRadius: '0.5vw'}} onClick={() => {
                                                    setCurrentATORetrieveal(option)
                                                    
                                                }} selected={currentATORetrieveal == option}>{option? 'Yes': 'No'}</TransparentButton>
                                            </span>)}

                                
                                            
                
                                        </div>
                                        
                                        
                                    </Form.Group>

                                    {/* Accounting Software */}
                                    <Form.Group style={{ marginBottom: '2vw', textAlign: 'left' }}>
                                        <p style={{ fontSize: '0.9vw', fontWeight: '500', marginLeft: '0.2vw' }}>Accounting Software</p>
                                        <p style={{fontSize: '0.75vw', color: 'rgb(255, 255, 255, 0.6)', marginLeft: '0.2vw'}}>This option allows Gayatri to download reports from the chosen accounting software and populate corresponding tabs in workpaper.</p>

                                        <div style={{display: 'inline-block', backgroundColor: 'rgb(0, 0, 0, 0.2)', borderRadius: '0.6vw', padding: '0.3vw'}}>
                      

                                            {/* <div style={{pointerEvents: 'none'}}> */}
                                                {['XERO', 'MYOB', 'NONE'].map( (option, index) => <span style={{}}>
                                                    <TransparentButton style={{padding: '0.3vw 0.75vw', borderRadius: '0.5vw'}} onClick={() => {
                                                        setCurrentAccountingSoftware(option.toLowerCase())
                                                        
                                                    }} selected={currentAccountingSoftware == option.toLowerCase()}>{option}</TransparentButton>
                                                </span>)}
                                            {/* </div> */}

                                
                                            
                
                                        </div>
                                        {/* <div style={{display: 'inline-block', backgroundColor: 'rgb(255, 255, 255, 0.05)', borderRadius: '2vw', padding: '0.15vw', marginBottom: '1vw'}}>
                    
                                            <span style={{}}>
                                                
                                                    <TransparentButton disabled={!asAccounts.includes('xero')} style={{padding: '0.3vw 1vw'}} onClick={() => {
                                                        setCurrentAccountingSoftware('xero')
                                                    }} selected={currentAccountingSoftware == 'xero'}>XERO</TransparentButton>
                                            

                                                
                                            </span>

                                
                                            <span style={{}}><TransparentButton disabled={!asAccounts.includes('myob')} style={{padding: '0.3vw 1vw'}} onClick={() => {
                                                    setCurrentAccountingSoftware('myob')
                                                }} selected={currentAccountingSoftware == 'myob'}>MYOB</TransparentButton></span>


                                            <span style={{}}><TransparentButton style={{padding: '0.3vw 1vw'}} onClick={() => {
                                                    setCurrentAccountingSoftware('none')
                                                }} selected={currentAccountingSoftware == 'none'}>NONE</TransparentButton></span>
                    
                                        </div> */}

                                        {/* {!asAccounts.includes('myob') && <p style={{color: 'rgba(255, 255, 255, 0.75)', fontSize: '0.7vw', margin: 0, marginBottom: '0.25vw', marginTop: '0.5vw'}}>* Please provide MYOB credential to select MYOB.</p>}
                                        {!asAccounts.includes('xero') && <p style={{color: 'rgba(255, 255, 255, 0.75)', fontSize: '0.7vw', margin: 0, marginBottom: '0.25vw'}}>* Please provide XERO credential to select XERO.</p>}
                                         */}
                                        
                                    </Form.Group>
                                
                                </Form>
                            </>}
                            { clientAdding == 'details' && <>
                                <Form>
                                    {/* ABN */}
                                    <Form.Group style={{ marginBottom: '2vw', textAlign: 'left'}}>
                                    <p style={{ fontSize: '0.9vw' , fontWeight: '500', marginLeft: '0.2vw'}}>Australian Business Number { !currentATORetrieveal && <span style={{color: 'rgb(255, 255, 255, 0.6)', fontSize: '0.75vw'}}>{"(Optional)"}</span> }</p>
                                    <p style={{fontSize: '0.75vw', color: 'rgb(255, 255, 255, 0.6)', marginLeft: '0.2vw'}}>Australian Business Number associated with the provided client name.</p>
                                        
                                    <Form.Control style={{ height: '3vw', backgroundColor: 'rgba(0,0,0,.25)', border: 'none', borderRadius: '0.5vw', fontSize: '0.8vw', paddingLeft: '1vw' }} value={currentABN} type="text" placeholder="Client ABN" ref={abnRef} onChange={(e) => {
                                        setCurrentABN(e.target.value)
                                        setAbnValidity(isValidABN(e.target.value))
                                    }}/>
                                    {!abnValidity && currentATORetrieveal && (
                                        <p style={{ color: 'red', fontSize: '0.8vw' , marginTop: '0.5vw'}}>Invalid ABN</p>
                                        )}
                                    </Form.Group>

                                

                                    {/* Alternative Name */}
                                    <Form.Group style={{ marginBottom: '2vw', textAlign: 'left' , display: 'none'}}>
                                        <p style={{ fontSize: '0.9vw', fontWeight: '500' }}>Alternative Name (optional)</p>
                                        <Form.Control style={{ height: '2.5vw', backgroundColor: 'rgba(255,255,255,.075)', border: 'none', borderRadius: '0.5vw', fontSize: '0.8vw', paddingLeft: '1vw' }} type="text" placeholder="Enter alternative name" ref={alternativeNameRef} />
                                    </Form.Group>

                                    {/* AS Client Name */}
                                    { currentAccountingSoftware != 'none' && <Form.Group style={{ marginBottom: '2vw', textAlign: 'left' }}>
                                        <p style={{ fontSize: '0.9vw', fontWeight: '500', marginLeft: '0.2vw' }}>Accounting Software Client Name</p>
                                        <p style={{fontSize: '0.75vw', color: 'rgb(255, 255, 255, 0.6)', marginLeft: '0.2vw'}}>Please provide the same Client Name as displayed in the chosen accounting software.</p>
                                        <Form.Control style={{ height: '3vw', backgroundColor: 'rgba(0,0,0,.25)', border: 'none', borderRadius: '0.5vw', fontSize: '0.8vw', paddingLeft: '1vw' }} type="text" placeholder="AS Client Name" value={currentASClientName} ref={asClientNameRef} onChange={(e) => setCurrentASClientName(e.target.value)}/>
                                    </Form.Group>}

                                    {/* Password (Optional) */}
                                    {false && (
                                        <Form.Group style={{ marginBottom: '2vw', textAlign: 'left' }}>
                                        <p style={{ fontSize: '0.9vw', fontWeight: '500' }}>MYOB Client Password (Optional)</p>
                                        <Form.Control
                                            style={{
                                            height: '2.5vw',
                                            backgroundColor: 'rgba(255,255,255,.1)',
                                            border: '1px solid rgb(255, 255, 255, 0.2)',
                                            borderRadius: '0.4vw',
                                            fontSize: '1vw',
                                            paddingLeft: '1vw'
                                            }}
                                            type="password"
                                            placeholder="Client Password"
                                            ref={passwordOptionalRef}
                                        />
                                        </Form.Group>
                                    )}


                                    {/* Accounting Basis */}
                                    <Form.Group style={{display: 'none',  marginBottom: '2vw', textAlign: 'left' }}>
                                        <p style={{ fontSize: '0.9vw', fontWeight: '500' }}>Accounting Basis</p>
                                        <p style={{fontSize: '0.75vw', color: 'rgb(255, 255, 255, 0.6)'}}>Please provide the accounting basis for this client.</p>
                                            
                                        <div style={{display: 'inline-block', backgroundColor: 'rgb(255, 255, 255, 0.05)', borderRadius: '2vw', padding: '0.15vw'}}>
                    
                                            <span style={{}}>
                                                
                                                    <TransparentButton style={{padding: '0.3vw 0.75vw'}} onClick={() => {
                                                        setcurrentAccountingBasis('Cash')
                                                    }} selected={currentAccountingBasis == 'Cash'}>Cash</TransparentButton>
                                            

                                                
                                            </span>

                                
                                            <span style={{}}><TransparentButton style={{padding: '0.3vw 0.75vw'}} onClick={() => {
                                                    setcurrentAccountingBasis('Accrual')
                                                }} selected={currentAccountingBasis == 'Accrual'}>Accrual</TransparentButton></span>
                    
                                        </div>

                                    
                                        </Form.Group>

                                    {/* BAS Lodgement */}
                                    
                                    { currentATORetrieveal && <Form.Group style={{display: 'none', marginBottom: '2vw', textAlign: 'left' }}>
                                        <p style={{ fontSize: '0.9vw', fontWeight: '500' }}>BAS Lodgement</p>
                                        <p style={{fontSize: '0.75vw', color: 'rgb(255, 255, 255, 0.6)'}}>Please provide the frequency of BAS Lodgment.</p>
                                    
                                        <div style={{display: 'inline-block', backgroundColor: 'rgb(255, 255, 255, 0.05)', borderRadius: '2vw', padding: '0.15vw'}}>
                    
                                            <span style={{}}>
                                                
                                                    <TransparentButton style={{padding: '0.3vw 0.75vw'}} onClick={() => {
                                                        setcurrentBasLodgement('Quarterly')
                                                    }} selected={currentBasLodgement == 'Quarterly'}>Quarterly</TransparentButton>
                                            

                                                
                                            </span>

                                
                                            <span style={{}}><TransparentButton style={{padding: '0.3vw 0.75vw'}} onClick={() => {
                                                    setcurrentBasLodgement('Monthly')
                                                }} selected={currentBasLodgement == 'Monthly'}>Monthly</TransparentButton></span>
                    
                                        </div>

                                        
                                    </Form.Group>}
                                </Form>
                            </>}
                            {clientAdding == 'processing' && <>
                            <Form.Group controlId="formFileLg" className="mb-3">
                                <div style={{textAlign: 'center', marginTop: '25vh'}}>
                                    <Spinner animation="border" variant="secondary" style={{width: '5rem', height: '5rem'}}/>
                                    <p style={{fontSize: '1.1rem', fontWeight: '400', marginTop: '30px'}}>Adding client to the queue</p>
                                </div>
                                {/* <p style={{fontSize: '1.3rem'}}>Please Wait!</p>
                                <p style={{color: 'rgb(255, 255, 255, 0.6)'}}>This might take a while ...</p> */}
                            </Form.Group>
                            </>}
                            { clientAdding == 'added' && <>
                                <div style={{textAlign: 'left'}}>
                                    { formatABN(currentABN) && <><p style={{fontWeight: '600', fontSize: '0.9vw'}}>Client ABN</p>
                                    <p style={{color: 'rgb(255, 255, 255, 0.7)', fontSize: '0.8vw'}}><span style={{fontFamily: "'Consolas', Courier, monospace"}}>{formatABN(currentABN) || 'NOT PROVIDED'}</span></p></>}
                                    <p style={{fontWeight: '600', fontSize: '0.9vw', paddingTop: '1vw'}}>Client Name</p>
                                    <p style={{color: 'rgb(255, 255, 255, 0.7)', fontSize: '0.8vw'}}>{currentClientName}</p>
                                    <p style={{fontWeight: '600', fontSize: '0.9vw', paddingTop: '1vw'}}>ATO Data Retrieval</p>
                                    <p style={{color: 'rgb(255, 255, 255, 0.7)', fontSize: '0.8vw'}}>{currentATORetrieveal? 'YES': 'NO'}</p>
                                    <p style={{fontWeight: '600', fontSize: '0.9vw', paddingTop: '1vw'}}>Accounting Software</p>
                                    <p style={{color: 'rgb(255, 255, 255, 0.7)', fontSize: '0.8vw'}}>{currentAccountingSoftware.toUpperCase()}</p>
                                    { currentAccountingSoftware != 'none' && <><p style={{fontWeight: '600', fontSize: '0.9vw', paddingTop: '1vw'}}>AS Client Name</p>
                                    <p style={{color: 'rgb(255, 255, 255, 0.7)', fontSize: '0.8vw'}}>{currentASClientName}</p></> }
                                    { currentATORetrieveal && <><p style={{fontWeight: '600', fontSize: '0.9vw', paddingTop: '1vw'}}>Accounting Basis</p>
                                    <p style={{color: 'rgb(255, 255, 255, 0.7)', fontSize: '0.8vw'}}>{currentAccountingBasis}</p>
                                    <p style={{fontWeight: '600', fontSize: '0.9vw', paddingTop: '1vw'}}>BAS Lodgement</p>
                                    <p style={{color: 'rgb(255, 255, 255, 0.7)', fontSize: '0.8vw'}}>{currentBasLodgement}</p></> }
                                </div>
                            
                            </>}

                            { clientAdding == 'error' && <>
                                <div style={{textAlign: 'left'}}>
                                    <p style={{fontSize: '1vw', fontWeight: '400', marginBottom: '1vw'}}>{errorMessage}</p>
                                </div>
                            
                            </>}
                            </Modal.Body>

                        <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '20px', padding: '0 20px', flexDirection: 'column' }}>

                            {/* {(clientAdding === 'init' || clientAdding === 'added' || clientAdding === 'error') && (
                                <TextButton variant='outlined' style={{ fontSize: '0.9vw', color: 'white', fontWeight: '500', padding: '0.25vw 1.5vw', marginRight: '1vw', borderRadius: '1.5vw',  textDecoration: 'none' , borderColor: 'rgb(255, 255, 255, 0.15)'}} onClick={handleClose}>
                                Close
                                </TextButton>
                            )} */}

                            {(clientAdding === 'init') && (
                                // <OverlayTrigger
                                //     key={'top-next'}
                                //     placement={'top'}
                                //     overlay={
                                //         <Tooltip id={`tooltip-next`}>
                                //             Proceed to the next step
                                //         </Tooltip>
                                //     }
                                // >
                                //     <Button
                                //         variant="link"
                                //         style={{
                                //             fontSize: '0.9vw',
                                //             color: 'white',
                                //             fontWeight: '600',
                                //             cursor: 'pointer',
                                //             padding: '0.3vw 1vw',
                                //             textDecoration: 'none',
                                //             backgroundColor: '#f06362'
                                //         }}
                                //         onClick={() => setClientAdding('details')}
                                //         disabled={currentClientName.length === 0}
                                //     >
                                //         Next
                                //     </Button>
                                // </OverlayTrigger>
                                // <Button variant='contained' style={{ fontSize: '0.9vw', color: 'white', fontWeight: '500', cursor: 'pointer', padding: '0.25vw 1.5vw', borderRadius: '1.5vw', backgroundColor: '#f25252', borderColor: '#f25252', textTransform: 'none'}} onClick={() => {
                                //         if(currentClientName.length == 0){
                                //             setSnackBarMessage('Client name cannot be empty')
                                //             setSnackBarShow(true)
                                //             return
                                //         }
                                //         if(currentAccountingSoftware == 'none' && !currentATORetrieveal){
                                //             setSnackBarMessage('Please select Accounting Software or ATO Retrieval')
                                //             setSnackBarShow(true)
                                //             return
                                //         }
                                //         setClientAdding('details')

                                //     }}>
                                //     Next
                                // </Button>
                                <Button onClick={() => {
                                    if(currentClientName.length == 0){
                                        setSnackBarMessage('Client name cannot be empty')
                                        setSnackBarShow(true)
                                        return
                                    }
                                    if(currentAccountingSoftware == 'none' && !currentATORetrieveal){
                                        setSnackBarMessage('Please select Accounting Software or ATO Retrieval')
                                        setSnackBarShow(true)
                                        return
                                    }
                                    if( !asAccounts.includes(currentAccountingSoftware)){
                                        setSnackBarMessage(`${currentAccountingSoftware.toUpperCase()} account has not been configured`)
                                        setSnackBarShow(true)
                                        return
                                    }
                                    setClientAdding('details')

                                }} variant="outlined" style={{
                                    borderColor: 'rgb(255, 255, 255, 1)', color: 'rgb(255, 255, 255, 0.9)', marginLeft: '2vw', marginRight: '2vw', padding: '0.7vw', marginBottom: '1.5vw', border: 'none', backgroundImage: 'linear-gradient(rgb(255, 255, 255, 0.05), rgb(10, 10, 10, 0.05)),linear-gradient(#f25252, #f25252)', borderRadius: '0.5vw', textTransform: 'none', fontSize: '1vw', fontWeight: 500, letterSpacing: '0.08vw'
                                , marginTop: '1vw'}}>Next</Button>
                            )}

                            

                            {clientAdding === 'details' && (
                                // <OverlayTrigger
                                //     key={'top-submit'}
                                //     placement={'top'}
                                //     overlay={
                                //         <Tooltip id={`tooltip-submit`}>
                                //             Submit the form
                                //         </Tooltip>
                                //     }
                                // >
                                //     <Button
                                //         variant="link"
                                //         style={{
                                //             fontSize: '0.9vw',
                                //             color: 'white',
                                //             fontWeight: '600',
                                //             cursor: 'pointer',
                                //             padding: '0.3vw 0.5vw',
                                //             textDecoration: 'none',
                                //             backgroundColor: '#f06362',
                                //             marginRight: '20px'
                                //         }}
                                //         onClick={addUser}
                                //         disabled={(!abnValidity && currentATORetrieveal) || (currentAccountingSoftware !== 'none' && currentASClientName === '')}
                                //     >
                                //         Submit
                                //     </Button>
                                // </OverlayTrigger>
                                <Button onClick={addUser} variant="outlined" style={{
                                    borderColor: 'rgb(255, 255, 255, 1)', color: 'rgb(255, 255, 255, 0.9)', marginLeft: '2vw', marginRight: '2vw', padding: '0.7vw', marginBottom: '1.5vw', border: 'none', backgroundImage: 'linear-gradient(rgb(255, 255, 255, 0.05), rgb(10, 10, 10, 0.05)),linear-gradient(#f25252, #f25252)', borderRadius: '0.5vw', textTransform: 'none', fontSize: '1vw', fontWeight: 500, letterSpacing: '0.08vw'
                                , marginTop: '1vw'}}>Confirm</Button>
                                // <Button variant='contained' style={{ fontSize: '0.9vw', color: 'white', fontWeight: '500', cursor: 'pointer', padding: '0.25vw 1.5vw', borderRadius: '1.5vw', backgroundColor: '#f25252', borderColor: '#f25252', textTransform: 'none'}} onClick={addUser}
                                // disabled={(!abnValidity && currentATORetrieveal) || (currentAccountingSoftware !== 'none' && currentASClientName === '')}>
                                //     Submit
                                // </Button>
                            )}

                            {(clientAdding === 'details') && (

                                <Button onClick={() => setClientAdding('init')} variant="outlined" style={{
                                    borderColor: 'rgb(255, 255, 255, 1)', color: 'rgb(255, 255, 255, 0.8)', marginLeft: '2vw', marginRight: '2vw', padding: '0.7vw', marginBottom: '1.5vw', border: 'none', backgroundColor: 'rgb(255, 255, 255, 0.05)', borderRadius: '0.5vw', textTransform: 'none', fontSize: '1vw', fontWeight: 500, letterSpacing: '0.08vw'
                                , marginTop: '0vw'}}>Previous</Button>
                                
                                // <TextButton variant='outlined' style={{ fontSize: '0.9vw', color: 'white', fontWeight: '500', padding: '0.25vw 1.5vw', marginRight: '1vw', borderRadius: '1.5vw',  textDecoration: 'none' , borderColor: 'rgb(255, 255, 255, 0.15)'}} onClick={() => setClientAdding('init')}>
                                //     Previous
                                // </TextButton>
                            )}
                        </div>

                    </Modal>


                    {/* popup for upload client */}
                    <Offcanvas 
                        show={uploadClientShow} 
                        onHide={handleUploadClientClose} 
                        placement="end" 
                        style={{
                            backgroundColor: 'rgba(32, 30, 34, 0.7)', 
                            '-webkit-backdrop-filter': 'blur(10px)',
                            backdropFilter: 'blur(50px)', 
                            border: '1px solid rgba(255, 255, 255, 0.2)', 
                            paddingLeft: '1vw',
                            paddingRight: '1vw',
                            paddingTop: '1vw',
                            color: 'white', 
                            display: 'flex', 
                            flexDirection: 'column', 
                            margin: '1vw',
                            borderRadius: '1vw',
                            width: '23vw',
                        }}
                    >                        
                        <div style={{flex: 1}}>
                            <Offcanvas.Header>
                                <Offcanvas.Title style={{fontSize: '2vw'}}>Upload Bulk Clients</Offcanvas.Title>
                            </Offcanvas.Header>
                            <Offcanvas.Body style={{paddingTop: '1vw', height: '100%'}}>
                                <Form.Group controlId="formFileLg" className="mb-3">
                                    <p style={{fontSize: '1.6vw', fontWeight: '500'}}>Instructions</p>
                                    <p style={{marginBottom: 0, fontSize: '1vw'}}>1. Fill in the CSV template</p>
                                    <div style={{padding: '1vw 0'}}>
                                        <a className="btn btn-primary" style={{fontSize: '1vw', fontWeight: '600', cursor: 'pointer', textDecoration: 'none', borderRadius: '2vw', padding: '1vw 2.5vw', margin: '0.5vw 0'}}>Download template</a>
                                    </div>

                                    <Form.Label style={{marginBottom: '2vw', marginTop: '2vw', fontSize: '1vw'}}>2. Upload the CSV template</Form.Label>
                                    <div className="form-group files" style={{ 
                                        border: '1px solid rgba(255, 255, 255, 0.2)', 
                                        borderRadius: '0.5vw', 
                                        padding: '2vw', 
                                        width: '100%', 
                                        height: '15vw', 
                                        display: 'flex', 
                                        flexDirection: 'column', 
                                        alignItems: 'center', 
                                        justifyContent: 'center', 
                                        position: 'relative',
                                    }}>
                                        <i className="fa-solid fa-upload" style={{ color: '#ffffff', fontSize: '1.6vw' }}></i>
                                        <input 
                                            style={{ 
                                                width: '100%', 
                                                height: '100%', 
                                                position: 'absolute', 
                                                top: 0, 
                                                left: 0, 
                                                opacity: 0, 
                                                cursor: 'pointer',
                                                fontSize:'1.3vw'
                                            }} 
                                            type="file" 
                                            className="form-control" 
                                            multiple 
                                            accept=".csv"
                                        />
                                        <div style={{ zIndex: 1, fontSize: '1vw' }}>Choose a file.</div>
                                    </div>
                                </Form.Group>
                            </Offcanvas.Body>
                        </div>

                        <div style={{textAlign: 'right', paddingBottom: '1.5vw'}}>
                            <span style={{ fontSize: '1.3vw', color: '#ee7170', fontWeight: '600', cursor: 'pointer', padding: '1.2vw', paddingBottom:'0vw' }} onClick={handleUploadClientClose}>
                                Close
                            </span>
                            <button 
                                type='button' 
                                style={{ 
                                    color: uploadAllowed ? 'rgb(255, 255, 255, 0.9)' : 'rgb(255, 255, 255, 0.6)', 
                                    fontSize: '1.3vw', color: '#ee7170', fontWeight: '600', cursor: 'pointer', padding: '1.2vw', paddingBottom:'0vw',
                                    backgroundColor: 'transparent', 
                                    border: 'transparent'
                                }} 
                                onClick={handleUploadFile} 
                                disabled={!uploadAllowed}
                            >
                                Upload
                            </button>
                        </div>
                    </Offcanvas>
                    
                    <ThemeProvider theme={darkTheme}>

                    {/* table */}
                    <div style={{lineHeight: '2vw', marginLeft: '2vw', marginRight: '2vw', border: '1px solid rgb(255, 255, 255, 0.15)', borderRadius: '1.5vw', textAlign: 'left'}}>
                        <List className="table table-hover table-dark" style={{ color: 'rgb(255, 255, 255, 0.9)', borderRadius: '1vw', fontSize: '0.75vw', backgroundColor: 'rgb(255, 255, 255, 0.0)', margin: 0, padding: 0}}>
                            <ListSubheader style={{fontWeight: 600, fontSize: '0.7vw', lineHeight: '3vw', backgroundColor: 'rgb(255, 255, 255, 0.06)', color: 'rgb(255, 255, 255, 0.85)', borderTopLeftRadius: '1.5vw', borderTopRightRadius: '1.5vw', padding: '0 2vw', display: 'flex', flexDirection: 'row'}}>
                                {/* <div style={{display: 'flex', fontWeight: '600'}}> */}
                                    <div scope="col" style ={{padding:'0.1vw 0', flex: 3}}>ABN</div>
                                    <div scope="col" style ={{padding:'0.1vw 0', flex: 6}}>CLIENT NAME</div>
                                    {/* <th scope="col" style ={{padding:'0.5vw 1.5vw', width: '30%'}}>AS CLIENT NAME</th> */}
                                    <div scope="col" style ={{padding:'0.1vw 0', flex: 3}}>ATO DATA</div>
                                    <div scope="col" style ={{padding:'0.1vw 0', flex: 3}}>ACCOUNTING SOFTWARE</div>
                                    {/* <th scope="col" style ={{padding:'0.5vw 1.5vw'}}>Accounting Basis</th>
                                    <th scope="col" style ={{padding:'0.5vw 1.5vw'}}>BAS Lodgement</th> */}
                                    <div scope="col" style ={{padding:'0.1vw 0', flex: 1}}>STATUS</div>

                                {/* </div> */}
                            </ListSubheader>
                            <div style={{fontSize: '0.65vw', color: 'rgb(255, 255, 255, 0.75)'}}>
                                {currentItems.map((item) => (
                                    <ListItemButton id={`client-abn-${item.client_abn}`} key={item.id} style={{"cursor": "pointer", lineHeight: '2vw', display: 'table-row', display: 'flex', flexDirection: 'row', borderTop: '1px solid rgb(255, 255, 255, 0.05)', fontSize: '0.8vw', padding: '0.5vw 2vw'}} onClick={() => {
                                        setDeleteID(item.id)
                                        setCurrentABN(item.client_abn);
                                        setCurrentClientName(item.client_name);
                                        setCurrentAlternativeName(item.alt_client_name);
                                        setCurrentASClientName(item.as_client_name);
                                        setAsClientNameValue(item.as_client_name);
                                        setCurrentAccountingSoftware(item.financial_sw_type);
                                        setcurrentAccountingBasis(item.accounting_basis);
                                        setcurrentBasLodgement(item.bas_lodgement);
                                        setCurrentStatus(item.client_status);
                                        setOwnerEmails(item.owners);
                                        console.log(item)
                                        setCurrentATORetrieveal(item.ato_data);
                                        setShowPopup(true);
                                    }}>          
                                        {/* <ListItemButton style={{flex: 1}}> */}
                                        <div scope="col" style ={{padding:'0.1vw 0', flex: 3, fontFamily: "'Consolas', Courier, monospace", letterSpacing: formatABN(item.client_abn) == 'NOT PROVIDED'? '0.15vw': '0.05vw'}}>{formatABN(item.client_abn)}</div>
                                        <div scope="col" style ={{padding:'0.1vw 0', flex: 6}}>{item.client_name}</div>
                                        {/* <th scope="col" style ={{padding:'0.5vw 1.5vw', width: '30%'}}>AS CLIENT NAME</th> */}
                                        <div scope="col" style ={{padding:'0.1vw 0', flex: 3}}>{item.ato_data? 'YES': 'NO'}</div>
                                        <div scope="col" style ={{padding:'0.1vw 0', flex: 3}}>{item.financial_sw_type.toUpperCase()}</div>
                                        {/* <th scope="col" style ={{padding:'0.5vw 1.5vw'}}>Accounting Basis</th>
                                        <th scope="col" style ={{padding:'0.5vw 1.5vw'}}>BAS Lodgement</th> */}
                                        <div scope="col" style ={{padding:'0.1vw 0', flex: 1}}>
                                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{item.status_detail?item.status_detail:'Unknown Message'}</Tooltip>}>
                                                <span className="d-inline-block">
                                                    <span>
                                                        {item.status == 'done'? 'Active':(item.status == 'ctlreq' || item.status == 'processing'? 'Pending': 'Fail')}
                                                    </span>
                                                </span>
                                            </OverlayTrigger>
                                        </div>
                                            {/* <div style ={{padding:'0.1vw 0vw', fontFamily: "'Consolas', Courier, monospace", letterSpacing: formatABN(item.client_abn) == 'NOT PROVIDED'? '0.15vw': '0.05vw', flex: 3}}>{formatABN(item.client_abn)}</div>
                                            <div style ={{padding:'0.1vw 0vw', flex: 6}}>{item.client_name}</div>
        
                                            <div style ={{padding:'0.1vw 0vw', flex: 3}}>{item.ato_data? 'YES': 'NO'}</div>
                                            <div style ={{padding:'0.1vw 0vw', flex: 3}}>{item.financial_sw_type.toUpperCase()}</div>

                                            <div style ={{padding:'0.1vw 1.5vw', cursor: 'help', flex: 1}}>
                                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{item.status_detail?item.status_detail:'Unknown Message'}</Tooltip>}>
                                                    <span className="d-inline-block">
                                                        <span>
                                                            {item.status == 'done'? 'Active':(item.status == 'ctlreq' || item.status == 'processing'? 'Pending': 'Fail')}
                                                        </span>
                                                    </span>
                                                </OverlayTrigger>
                                            </div> */}
                                        {/* </ListItemButton> */}
                                    </ListItemButton>
                                ))}
                                { currentItems.length == 0 && !isLoading &&
                                    <div style={{display: 'flex'}}>
                                        <div style={{flex: 1}}><p style={{textAlign: 'center', marginTop: '30px', marginBottom: '30px'}}>No client found</p></div>
                                    </div>
                                }
                                { currentItems.length == 0 && isLoading &&
                                    <div style={{display: 'flex'}}>
                                        <div style={{flex: 1}}><p style={{textAlign: 'center', marginTop: '30px', marginBottom: '30px'}}><CircularProgress color="inherit" /></p></div>
                                    </div>
                                }
                                
                            </div>
                        </List>
                    </div>
                    </ThemeProvider>

                    <div style={{ marginTop: '1vw', marginLeft: '2vw', marginRight: '2vw', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', textAlign: 'left' }}>
                        <div style={{ flex: 1 }}>
                            <p style={{ color: 'rgb(255, 255, 255, 0.5)', fontSize: '0.8vw', lineHeight:'normal'}}>
                                Showing {displayedFirstItem} to {displayedLastItem} of {filteredData.length} entries
                            </p>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <button
                                onClick={prevPage}
                                disabled={currentPage === 1}
                                style={{
                                    width: '2.5vw',
                                    height: '2.5vw',
                                    borderRadius: '0.5vw',
                                    marginRight: '6px',
                                    color: currentPage === 1 ? 'gray' : 'white',
                                    backgroundColor: 'rgb(255, 255, 255, 0.02)',
                                    border: '0.5px solid rgb(255, 255, 255, 0.3)',
                                    // color: 'white',
                                    cursor: currentPage === 1 ? 'not-allowed' : 'pointer',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    fontSize: '0.9vw',
                                }}
                            >
                                <span dangerouslySetInnerHTML={{ __html: '&larr;' }} />
                            </button>

                            <button
                                onClick={nextPage}
                                disabled={currentPage === Math.ceil(data.length / itemsPerPage)}
                                style={{
                                    width: '2.5vw',
                                    height: '2.5vw',
                                    borderRadius: '0.5vw',
                                    marginLeft: '6px',
                                    color: 'white',
                                    backgroundColor: 'rgb(255, 255, 255, 0.02)',
                                    border: '0.5px solid rgb(255, 255, 255, 0.3)',
                                    cursor: currentPage === Math.ceil(data.length / itemsPerPage) ? 'not-allowed' : 'pointer',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    fontSize: '0.9vw',
                                }}
                            >
                                <span dangerouslySetInnerHTML={{ __html: '&rarr;' }} />
                            </button>
                        </div>
                    </div>

                    {/* offcanvas for */}
                    <div style={{maxHeight: 'calc(100vh - 300px)', overflowY: 'auto', marginLeft: '2rem', marginRight: '2rem' }}>
                        {/* <Offcanvas 
                            show={showPopup} 
                            onHide={() => setShowPopup(false)} 
                            placement="end" 
                            style={{
                                backgroundColor: 'rgba(32, 30, 34, 0.7)', 
                                '-webkit-backdrop-filter': 'blur(10px)',
                                backdropFilter: 'blur(50px)',
                                border: '1px solid rgba(255, 255, 255, 0.2)', 
                                paddingTop: '1vw',
                                color: 'white', 
                                display: 'flex', 
                                flexDirection: 'column', 
                                margin: '1vw',
                                borderRadius: '2vw',
                                width: '23vw', // Adjusted width
                                overflowY: 'auto' // Changed to 'auto' for better scrolling behavior
                            }}
                        >
                            <div style={{display: 'flex', flexDirection: 'column'}}>
                                <Offcanvas.Header style={{padding: '1vw 1.5vw', flex: 1}}>
                                    <Offcanvas.Title style={{fontSize: '1.2vw', padding: '0', textAlign:'center', flex: 1}}><p style={{margin: 0, textAlign:'center'}}>{currentClientName}</p></Offcanvas.Title>
                                </Offcanvas.Header>
                            </div> */}

                    <Modal
                        show={showPopup}
                        onHide={() => setShowPopup(false)}
                        dialogClassName="modal-50w"
                        aria-labelledby="example-custom-modal-styling-title"
                    >     
                        <Modal.Body style={{padding: '2vw 0', paddingBottom: '0vw', textAlign: 'center'}}>

                        <h4 style={{textAlign: 'center', fontSize: '1.3vw', marginBottom: '1.5vw', fontWeight: '500'}}>{currentClientName}</h4>

                            <div style={{flex: 1, overflowY: 'auto'}}>
                                
                                <>

                                        <div style={{display: 'flex', flexDirection: 'column'}}>

                                            <div style={{flex: 1}}>

                                                <Accordion defaultActiveKey="0" variant="dark">
                                                    <Accordion.Item eventKey="0" style={{background: 'rgb(255, 255, 255, 0)', color: 'rgb(255, 255, 255, 0.9)'}}>
                                                        <ButtonBase style={{width: '100%', padding: 0, margin: '0.3vw 0'}}><Accordion.Header style={{width: '100%', margin: 0, padding: 0}}>General</Accordion.Header></ButtonBase>
                                                        <Accordion.Body style={{padding: '1vw 1.5vw', paddingBottom: '2vw'}}>
                                                            <p style={{fontSize: '0.9vw', fontWeight: '600', color: 'rgb(255, 255, 255, 0.8)'}}>Client Name</p>
                                                            <p style={{textAlign: 'left', fontSize: '0.75vw'}}>{currentClientName}</p>

                                                            <p style={{fontSize: '0.9vw', fontWeight: '600', marginTop: '1.5vw'}}>Accounting Software</p>
                                                            <p style={{fontSize: '0.75vw', color: 'rgb(255, 255, 255, 0.6)'}}>This option allows Gayatri to download reports from the chosen accounting software and populate corresponding tabs in workpaper.</p>

                                                            <div style={{display: 'inline-block', backgroundColor: 'rgb(0, 0, 0, 0.15)', borderRadius: '0.75vw', padding: '0.3vw', pointerEvents: auth.user?.profile.groups.includes("gayatri_admin")? 'auto' :'none'}} >
                      
                                                                {['XERO', 'MYOB', 'NONE'].map( (option, index) => <span style={{}}>
                                                                    <TransparentButton style={{padding: '0.3vw 0.75vw', borderRadius: '0.5vw'}} onClick={() => {
                                                                        setCurrentAccountingSoftware(option.toLowerCase())
                                                                        
                                                                    }} selected={currentAccountingSoftware == option.toLowerCase()}>{option}</TransparentButton>
                                                                </span>)}
                                        
                                                            </div>


                                                            
                                                            {/* <p style={{fontSize: '0.9vw', fontWeight: '600', marginTop: '1.5vw'}}>Accounting Basis</p>
                                                            <p style={{fontSize: '0.75vw', color: 'rgb(255, 255, 255, 0.6)'}}>Please provide the accounting basis for this client.</p>
                                                
                                                            <div style={{display: 'inline-block', backgroundColor: 'rgb(255, 255, 255, 0.15)', borderRadius: '2vw', padding: '0.15vw'}}>
                      
                                                                <span style={{}}>
                                                                    <TransparentButtonDark style={{padding: '0.3vw 0.75vw'}} onClick={() => {
                                                                        setcurrentAccountingBasis('Cash')
                                                                    }} selected={currentAccountingBasis == 'Cash'}>Cash</TransparentButtonDark>
                                                                </span>

                                                    
                                                                <span style={{}}>
                                                                    <TransparentButtonDark style={{padding: '0.3vw 0.75vw'}} onClick={() => {
                                                                        setcurrentAccountingBasis('Accrual')
                                                                    }} selected={currentAccountingBasis == 'Accrual'}>Accrual</TransparentButtonDark>
                                                                </span>
                                    
                                                            </div> */}

                                                            
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                    <Accordion.Item eventKey="1">
                                                        <ButtonBase style={{width: '100%', padding: 0, margin: '0.3vw 0'}}><Accordion.Header style={{width: '100%', margin: 0, padding: 0}}>Australian Tax Office</Accordion.Header></ButtonBase>
                                                        <Accordion.Body style={{padding: '1vw 1.5vw'}} >
                                                            <p style={{fontSize: '0.9vw', fontWeight: '600', color: 'rgb(255, 255, 255, 0.8)'}}>Australian Business Number</p> 
                                                            <p style={{textAlign: 'left', fontFamily: "'Consolas', Courier, monospace"}}>{formatABN(currentABN)}</p>
                                                            <Form.Group style={{display: 'none', marginBottom: '1vw',  marginTop: '2vw', textAlign: 'left' }}>
                                                                <p style={{ fontSize: '0.9vw', fontWeight: '500' }}>BAS Lodgement</p>
                                                                <p style={{fontSize: '0.75vw', color: 'rgb(255, 255, 255, 0.6)'}}>Please provide the frequency of BAS Lodgment.</p>
                                                            
                                                                <div style={{display: 'inline-block', backgroundColor: 'rgb(255, 255, 255, 0.15)', borderRadius: '2vw', padding: '0.15vw'}}>
                      
                                                                    <span style={{}}>
                                                                        
                                                                            <TransparentButtonDark style={{padding: '0.3vw 0.75vw'}} onClick={() => {
                                                                                setcurrentBasLodgement('Quarterly')
                                                                            }} selected={currentBasLodgement == 'Quarterly'}>Quarterly</TransparentButtonDark>
                                                                    

                                                                        
                                                                    </span>

                                                        
                                                                    <span style={{}}><TransparentButtonDark style={{padding: '0.3vw 0.75vw'}} onClick={() => {
                                                                            setcurrentBasLodgement('Monthly')
                                                                        }} selected={currentBasLodgement == 'Monthly'}>Monthly</TransparentButtonDark></span>
                                        
                                                                </div>

                                                            </Form.Group>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                    { currentAccountingSoftware != 'none' && <Accordion.Item eventKey="2">
                                                    <ButtonBase style={{width: '100%', padding: 0, margin: '0.3vw 0'}}><Accordion.Header style={{width: '100%', margin: 0, padding: 0}}>Accounting Software</Accordion.Header></ButtonBase>
                                                        <Accordion.Body style={{padding: '1vw 1.5vw', paddingBottom: '2vw'}}>
                                                            <p style={{fontSize: '0.9vw', fontWeight: '600'}}>AS Client Name</p>
                                                            <p style={{fontSize: '0.75vw', color: 'rgb(255, 255, 255, 0.6)'}}>Please provide the same Client Name as displayed in the chosen accounting software.</p>
                                                            <Form.Control style={{height: '2vw', backgroundColor: 'rgba(255,255,255,.05)', border: '1px solid rgb(255, 255, 255, 0.25)', borderRadius: '0.3vw', fontSize: '0.8vw', paddingLeft: '0.6vw'}} type="text" placeholder="Enter client name" value={asClientNameValue} onChange={handleAsClientNameChange} disabled={!auth.user?.profile.groups.includes("gayatri_admin")}/>
                                                        
                                                        </Accordion.Body>
                                                    </Accordion.Item>}
                                                    { auth.user?.profile.groups.includes("gayatri_admin") && <Accordion.Item eventKey="3">
                                                    <ButtonBase style={{width: '100%', padding: 0, margin: '0.3vw 0'}}><Accordion.Header style={{width: '100%', margin: 0, padding: 0}}>Permissions</Accordion.Header></ButtonBase>
                                                        <Accordion.Body  style={{padding: '1vw 1.5vw'}}>
                                                            <div>
                                                                <p style={{fontSize: '0.9vw', fontWeight: '600'}}>Assigned to</p>
                                                                <p style={{fontSize: '0.75vw', color: 'rgb(255, 255, 255, 0.6)'}}>Please specify users to have access to this client.</p>
                                                         
                                                                <ThemeProvider theme={darkTheme}>
                                                                    <Autocomplete
                                                                        multiple
                                                                        limitTags={2}
                                                                        id="multiple-limit-tags"
                                                                        options={userData}
                                                                        onChange={(event, value) => {
                                                                            setOwnerEmails(value)
                                                                          }}
                                                                        getOptionLabel={(option) => option.firstName && option.lastName? `${option.firstName} ${option.lastName}`: 'Anonymous User'}
                                                                        defaultValue={ownerEmails}
                                                                        renderInput={(params) => (
                                                                            <TextField {...params} label="limitTags" placeholder="Users" style={{fontSize: '0.7vw'}} />
                                                                        )}
                                                                        sx={{ width: '100%' }}
                                                                    />
                                                                </ThemeProvider>
                                                                {/* <Typeahead                         
                                                                    allowNew
                                                                    id="custom-selections-example"
                                                                    multiple
                                                                    newSelectionPrefix="Add items: "
                                                                    onChange={agentEmailChangedHandler}
                                                                    options={[]}
                                                                    placeholder="Email" 
                                                                    selected={ownerEmails}
                                                                    emptyLabel='Please enter Tax Agent Email'
                                                                    renderMenu={(results, menuProps) => {
                                                                        // Hide the menu when there are no results.
                                                                        if (!results.length) {
                                                                        return null;
                                                                        }
                                                                        return <TypeaheadMenu {...menuProps} options={results} />;
                                                                    }}
                                                                /> */}
                                                            </div>
                                                            
                                                            
                                                            <div style={{marginTop: '2vw', paddingBottom: '1.5vw'}}>
                                                                <p style={{fontSize: '0.9vw', fontWeight: '600'}}>Delete Client</p>
                                                                <p style={{fontSize: '0.75vw', color: 'rgb(255, 255, 255, 0.6)'}}>This client will be deleted but you can still access workpapers that have been created for this client.</p>
                                                    
                                                                <Button color="error" onClick={handleDeleteDialogClickOpen} variant="contained" style={{padding: '0.3vw 0.8vw', borderRadius: '1.5vw', marginRight: '15px', fontSize: '0.8vw', textTransform: 'none'}}>Delete</Button>
                                                            </div>
                                                        </Accordion.Body>
                                                    </Accordion.Item>}
                                                </Accordion>
                                            </div>

                                        </div>

                                    
                                </>

                            </div>

                                


                            

                            <div style={{display: 'flex', textAlign: 'right', padding: '1vw 3vw'}}>
                                    {/* <TextButton variant='outlined' style={{ fontSize: '0.9vw', color: 'white', fontWeight: '500', padding: '0.25vw 1.5vw', marginRight: '1vw', borderRadius: '1.5vw',  textDecoration: 'none' , borderColor: 'rgb(255, 255, 255, 0.15)'}} onClick={() => setShowPopup(false)}>
                                        Close
                                    </TextButton> */}
                                    {/* <Button variant='contained' style={{ fontSize: '0.9vw', color: 'white', fontWeight: '500', cursor: 'pointer', padding: '0.25vw 1.5vw', borderRadius: '1.5vw', backgroundColor: '#f25252', borderColor: '#f25252', textTransform: 'none'}} onClick={() => {
                                        const client = {
                                            id: deleteID,
                                            abn: currentABN,
                                            name: currentClientName,
                                            asName: asClientNameValue,
                                            accountingSoftware: currentAccountingSoftware,
                                            accountingBasis: currentAccountingBasis,
                                            assignments: ownerEmails.map(item => item.email),
                                            basLodgement: currentBasLodgement
                                        };

                                        editClientHandler(client);

                                    }}>
                                        Save
                                    </Button> */}

                                    <Button onClick={() => {
                                        const client = {
                                            id: deleteID,
                                            abn: currentABN,
                                            name: currentClientName,
                                            asName: asClientNameValue,
                                            accountingSoftware: currentAccountingSoftware,
                                            accountingBasis: currentAccountingBasis,
                                            assignments: ownerEmails.map(item => item.email),
                                            basLodgement: currentBasLodgement
                                        };

                                        editClientHandler(client);

                                    }} variant="outlined" style={{
                                    flex: 1, borderColor: 'rgb(255, 255, 255, 1)', color: 'rgb(255, 255, 255, 0.9)', marginLeft: '2vw', marginRight: '2vw', padding: '0.7vw', marginBottom: '1.5vw', border: 'none', backgroundImage: 'linear-gradient(rgb(255, 255, 255, 0.05), rgb(10, 10, 10, 0.05)),linear-gradient(#f25252, #f25252)', borderRadius: '0.5vw', textTransform: 'none', fontSize: '1vw', fontWeight: 500, letterSpacing: '0.08vw'
                                , marginTop: '1vw'}}>Save</Button>
                            </div>

                            </Modal.Body>

                            
                        </Modal>

                        {/* confirm delete pop up */}
                        {/* <Modal style={{ backgroundColor: 'rgb(0, 0, 0, 0.1)'}} contentClassName='whiteContentModal' show={show} onHide={onClose} centered>
                            <Modal.Header style={{ border: 0, color: '#ee7170', padding: 0}}>
                            </Modal.Header>
                            <Modal.Body style={{padding: '10px 30px', textAlign: 'center'}}>
                                <p style={{fontWeight: '500', fontSize: '1.3rem', paddingTop: '1.3rem', paddingBottom: '1rem'}} >Confirm Client Delete</p>
                                <Form.Group controlId="formFileLg" className="mb-4">
                                    <div><p style={{fontSize: '1.3vw', fontWeight: '400', color: 'rgb(255, 255, 255, 0.8)'}}>Are you sure you want to delete this client?</p></div>
                                </Form.Group>
                            </Modal.Body>
                            <Modal.Footer style={{border: 0, alignItems: 'center', justifyContent: 'center', borderTop: '1px solid rgb(255, 255, 255, 0.1)'}}>
                                <p style={{ color: '#ee7170', fontWeight: '600', cursor: 'pointer', flex: 1, textAlign: 'center', borderRight: '1px solid rgb(255, 255, 255, 0.1)'}} onClick={onDelete}>
                                    Delete
                                </p>
                                <p style={{ color: '#ee7170', fontWeight: '600', cursor: 'pointer', flex: 1, textAlign: 'center'}} onClick={onClose}>
                                    Close
                                </p>
                            </Modal.Footer>
                        </Modal> */}
                    </div>
                </div>
            </div>

            <ThemeProvider theme={darkTheme}>
                <Dialog
                    open={deleteDialogShow}
                    onClose={() => setDeleteDialogShow(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    
                >
                    <DialogTitle id="alert-dialog-title">
                    {"Client Delete"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Please confirm that you want to delete {currentClientName} from client list.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={() => setDeleteDialogShow(false)}>Cancel</Button>
                    <Button onClick={() => deleteClient(deleteID)} autoFocus>
                        Confirm
                    </Button>
                    </DialogActions>
                </Dialog>
            </ThemeProvider>
        </div>
    );
}